<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4>
              <i class="ti-palette"> </i> 杂项 | 物品借用
            </h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit">新增记录</el-button>
            <!-- <form id="fm_export" method="post" action="/utl/rent/export" target="_blank" style="display: inline-block; margin: 0px 10px">
              <input type="hidden" name="KEY" v-model="grid.sea.KEY" />
              <input type="hidden" name="STA" v-model="grid.sea.STA" />
              <input type="hidden" name="DTS" v-model="grid.sea.DTS" />
              <input type="hidden" name="DTE" v-model="grid.sea.DTE" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.STA" @change="filter" clearable placeholder="请选择状态">
                <el-option label="借出" value="1" />
                <el-option label="已还" value="2" />
              </el-select>
              <el-date-picker v-model="grid.date_range" @change="filter" type="daterange" value-format="yyyy-MM-dd"
                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left: 5px" />
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage">
              <el-table-column prop="NAME" label="物品名称" width="300" />
              <el-table-column prop="CNT" label="数量" width="80" align="center" />
              <el-table-column prop="HANDLE_NAME" label="经手人" width="80" align="center" />
              <el-table-column label="借出日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.EVENT_DT | datetime('YYYY-MM-DD') }}</template>
              </el-table-column>
              <el-table-column label="归还日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.RETURN_DT | datetime('YYYY-MM-DD') }}</template>
              </el-table-column>
              <el-table-column prop="DESCRIPTION" label="备注" width="300" />
              <el-table-column prop="STA_TXT" label="状态" width="80" align="center" />
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit" @click="revert(scope.row)"
                    v-if="scope.row.STA == 1">归还</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="rent_edit" @on-saved="filter" />
    </div>
  </div>
</template>
<style>
.el-radio {
  padding-top: 10px
}
</style>
<script>
import Edit from "./edit";
import Wgrid from "@/components/wgrid";
import Swal from "sweetalert2";
import jsFileDownload from 'js-file-download'
export default {
  name: "index",
  components: { Wgrid, Edit },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
        },
        ls: [],
        total: 0,
        loading: false
      },
    }
  },
  created() {
    this.getList();
  },
  methods: {
    filter() {
      if (this.grid.date_range != null && this.grid.date_range.length > 1) {
        this.grid.sea.DTS = this.grid.date_range[0];
        this.grid.sea.DTE = this.grid.date_range[1];
      }
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/UTL/RentApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    rowEdit(r) {
      this.$refs.rent_edit.init(r);
    },
    revert(r) {
      let self = this;
      Swal.fire({
        text: "真的要归还这个记录吗？",
        icon: "question",
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        showCancelButton: true,
      }).then((v) => {
        if (v.isConfirmed) {
          self.whale.remote.getResult({
            url: "/api/School/UTL/RentApi/Revert",
            data: { ID: r.ID },
            completed: function () {
              self.getList();
            }
          })
        }
      })
    },
    exportExcel(){
      let self = this;
      this.grid.loading = true;
      const formData = new FormData()
      formData.append('KEY', this.grid.sea.KEY || '')
      formData.append('STA', this.grid.sea.STA || '')
      formData.append('DTS', this.grid.sea.DTS || '')
      formData.append('DTE', this.grid.sea.DTE || '')
      this.whale.remote.getExport({
        url: "/api/School/UTL/MRent/Export",
        data: formData,
        completed: function (its) {
          // console.log('exls', its.Data)
          // self.whale.downLoad(its.Data)
          jsFileDownload(its, '导出杂项物品借用.xls')
          self.grid.loading = false;
        }
      })
    }
  }
}
</script>

